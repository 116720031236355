import axios from "axios";

export const fetchApi = async ({ method, endPoint, token, data, params, formData }) => {
    const headers = {};
    if (token) {
        headers.Authorization = 'Bearer gAAAAABnPR0fCqfHV9VKZ5db8pyiO_nsTL2LHTTvRaUKpZStm-AAaqSpA-dC9dtxSo9S4xIPH0OAISMGgq9Hmamgpca8FglHBh2ap-4tJJf9IBVvYlty2jI=';
    }

    if (formData) {
        headers['Content-Type'] = 'multipart/form-data';
        headers['Accept'] = 'multipart/form-data';
    } else {
        headers['Content-Type'] = 'application/json';
        headers['Accept'] = 'application/json';
    }
    const config = {
        method,
        url: `https://crms.ajcl.net:7119/api/${endPoint}`,
        headers,
        data: data ? data : undefined,
    };

    if (params) config.params = params;
    return await axios(config);
};

